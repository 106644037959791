export default [
   {
    header: 'Alarme maison',
  },
  {
    title: 'Alarme',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Visualisation',
        route: 'alarme',
      }
      
    ],
  } 
]
